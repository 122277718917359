import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpandableComponent } from '../components/expandable/expandable.component';

@NgModule({
  declarations: [ExpandableComponent],
  imports: [
    CommonModule,
  ],
  entryComponents: [ExpandableComponent],
  exports:[ExpandableComponent]
})
export class SharedModule { }
