import { StorageService } from "./services/storage/storage.service";
import { ConfigService } from "./services/config/config.service";
import { LoginService } from "./services/login/login.service";
import { Component, ViewChild } from "@angular/core";

import {
  Platform,
  NavController,
  AlertController,
  MenuController,
  IonContent
} from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { RecorteService } from "./services/recorte/recorte.service";
import { ConnectionService } from "ng-connection-service";
import { SwUpdate } from "@angular/service-worker";
import { Router, NavigationEnd } from "@angular/router";

declare let navigator: any;

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  @ViewChild(IonContent) content: IonContent;

  public appPages: Array<any>;
  public usuario: any;

  public showFabPWA: boolean = true;
  public deferredPrompt;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navCtrl: NavController,
    private recorteService: RecorteService,
    private storageService: StorageService,
    private loginService: LoginService,
    private connectionService: ConnectionService,
    private alertCtrl: AlertController,
    private swUpdate: SwUpdate,
    private router: Router,
    private configService: ConfigService,
    private menu: MenuController
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        //Analytics
        (<any>window).ga("set", {
          page: event.urlAfterRedirects,
          title: "Grifon Alerta Mobile"
        });
        (<any>window).ga("send", "pageview");
      }
    });
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
    var isMobile = window.orientation > -1;

    if (localStorage.getItem("showFabPWA") || !window["chrome"] || isMobile) {
      this.showFabPWA = false;
    }
    if (localStorage.getItem("filtros")) {
      localStorage.removeItem("filtros");
    }
    this.storageService.get("usuario").then(usuario => {
      this.usuario = usuario;
    });

    this.loginService.getNovoUsuario().subscribe(usuario => {
      this.usuario = usuario;
    });

    if (navigator.onLine) {
      this.recorteService.setNetwork("Online");
    } else {
      this.recorteService.setNetwork("Offline");
    }
    this.connectionService.monitor().subscribe(isConnected => {
      if (isConnected) {
        this.recorteService.setNetwork("Online");
        this.storageService.get("eventos").then(eventos => {
          if (eventos) {
            eventos.forEach((evento, index) => {
              if (evento.nome == "marcarComoLido") {
                this.recorteService
                  .setLido(evento.data.recorte, evento.data.usuario)
                  .subscribe(() => {
                    evento.data.recorte.nStLeitura = 1;
                    this.recorteService.emitLido(evento.data.recorte);
                  });
              }
              if (index == eventos.length - 1) {
                this.storageService.remove("eventos");
              }
            });
          }
        });
      } else {
        //offline
        this.recorteService.setNetwork("Offline");
      }
    });

    this.appPages = [
      {
        title: "Recortes Eletrônicos",
        url: "/tabs/recorte",
        direct: "root",
        icon: "mail-open"
      },
      {
        title: "Agenda de Prazo",
        url: "/tabs/recorte",
        direct: "root",
        disabled: true,
        icon: "calendar"
      },
      {
        title: "Andamento Processual",
        url: "/tabs/lista-processos",
        direct: "root",
        icon: "document"
      },

      {
        title: "Análise de Processo",
        url: "/tabs/recorte",
        direct: "root",
        disabled: true,
        icon: "search"
      },
      {
        title: "Transmissão para o tribunal",
        url: "/tabs/recorte",
        direct: "root",
        disabled: true,
        icon: "send"
      },
      {
        title: "Petição",
        url: "/tabs/recorte",
        direct: "root",
        disabled: true,
        icon: "create"
      },
      {
        title: "Títulos Grifon",
        url: "https://grifon.com.br/titulos",
        direct: "root",
        icon: "bookmarks"
      },
      {
        title: "Mais serviços",
        url: "/tabs/recorte",
        direct: "root",
        isParentItem: true,
        isOpen: false,
        childs: [
          {
            title: "Podcasts",
            url: "https://grifon.com.br/podcasts",
            direct: "root",
            icon: "microphone"
          },
          {
            title: "Jornal Grifon",
            url: "https://grifon.com.br/jornal",
            direct: "root",
            icon: "paper"
          },
          {
            title: "Consultas e pareceres",
            url: "https://grifon.com.br/consultas-e-pareceres",
            direct: "root",
            icon: "git-compare"
          },
          {
            title: "Cursos EAD",
            url: "https://ead.grifon.com.br/",
            direct: "root",
            icon: "school"
          },
          {
            title: "Assine",
            url: "https://grifon.com.br/#",
            direct: "root",
            icon: "md-create"
          },
          {
            title: "Livro",
            url: "https://www.amazon.com.br/dp/B07ZTVVB28/ref=cm_sw_em_r_mt_dp_U_UOBWDbF6CDSV2",
            direct: "root",

            icon: "book"
          },
          {
            title: "Retirada de cópia",
            url: "https://grifon.com.br/retirada-de-copias",
            direct: "root",
            icon: "copy"
          },
          {
            title: "Fale conosco",
            url: "https://grifon.com.br/#",
            direct: "root",
            icon: "chatbubbles"
          }
        ],
        icon: "more"
      }
    ];
    if (window['cordova']) {
      this.appPages.push({
        title: "Configurações",
        url: "/configuracoes",
        direct: "root",
        icon: "cog"
      });
    }

    this.initializeApp();
  }
  adicionarAtalho() {
    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice.then(choiceResult => {
      if (choiceResult.outcome === "accepted") {
        this.showFabPWA = false;
        localStorage.setItem("showFabPWA", "false");
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.backgroundColorByHexString("#000000");
      this.splashScreen.hide();
      if (window['cordova']) {
        let boletins = await JSON.parse(localStorage.getItem("boletins"));

        if (boletins) this.configService.checkTimeRangeBoletim(boletins);
      }
      this.storageService.get("token").then(token => {
        if (!token) {
          this.loginService.requestToken(false).subscribe(newToken => {
            this.storageService.set("token", newToken);
          });
        }
      });
    });
  }
  public async removerBotao() {
    const alert = await this.alertCtrl.create({
      header: "Atenção",
      message:
        "O botão para adicionar atalho em sua área de trabalho foi removido.",
      buttons: ["OK"]
    });
    this.showFabPWA = false;
    localStorage.setItem("showFabPWA", "false");
    return await alert.present();
  }
  goToEditProgile() {
    this.navCtrl.navigateForward("edit-profile");
  }
  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm("Uma nova versão está disponível, deseja atualizar?")) {
          window.location.reload();
        }
      });
    }
  }

  async logout() {
    let token = await this.storageService.get("token");
    await this.storageService.clear();
    await this.storageService.set("token", token);
    this.navCtrl.navigateRoot("/login");
  }

  async redirectPage(p) {
    if (!p.isParentItem) {
      if (!p.disabled) {
        this.menu.close();
        if(p.url.includes('http')) {
          window.open(p.url, '_blank');
        }
        else {
          this.navCtrl.navigateRoot(p.url);
        }
      } else {
        const alert = await this.alertCtrl.create({
          header: "Grifon Alerta",
          message: "Funcionalidade não implementada.",
          buttons: ["OK"]
        });
        return await alert.present();
      }
    }
  }
  public scrollToParent(page, id) {
    if (page.isParentItem) {
      if (!page.isOpen) {
        let yOffset = document.getElementById(id).offsetTop;
        this.content.scrollToPoint(0, yOffset - 15, 1000);
      }
      page.isOpen = !page.isOpen;
    }
  }
}
