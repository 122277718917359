import { SharedModule } from "./shared/shared.module";
import { SearchFilterPageModule } from "./pages/modal/search-filter/search-filter.module";
import { Interceptor } from "./interceptor";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { IonicStorageModule } from "@ionic/storage";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";

import { File } from "@ionic-native/file/ngx";
import { FileTransfer } from "@ionic-native/file-transfer/ngx";
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { DocumentViewer } from "@ionic-native/document-viewer/ngx";
import { SearchFilterProcessPageModule } from "./pages/modal/search-filter-process/search-filter-process.module";


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      backButtonText: "",
      mode: "md"
    }),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot({
      name: "__grifondb",
      driverOrder: ["websql", "sqlite", "indexeddb"],
      size: 4980736 // Size of database, in bytes. WebSQL-only for now.
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    }),
    SearchFilterPageModule,
    SearchFilterProcessPageModule,
    SharedModule
  ],
  providers: [
    StatusBar,
    File,
    FileTransfer,
    FileOpener,
    DocumentViewer,
    SplashScreen,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
