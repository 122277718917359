import { AlertController } from "@ionic/angular";
import { LoginService } from "./services/login/login.service";
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from "@angular/common/http";
import { Observable } from "rxjs";
import { mergeMap, catchError } from "rxjs/operators";

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private alertCtrl: AlertController
  ) {}
  async showAlert() {
    const alert = await this.alertCtrl.create({
      header: "Atenção",
      message: "Houve uma falha de conexão com o servidor.",
      buttons: ["OK"]
    });
    return await alert.present();
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let requestedToken = false;
    if (!request.url.includes("connect/token")) {
      return this.loginService.getToken().pipe(
        mergeMap(token => {
          if (token) {
            request = request.clone({
              headers: request.headers.set("Authorization", "Bearer " + token)
            });
          }

          return next.handle(request).pipe(
            catchError(error => {
              if (error.status == 401 && !requestedToken) {
                requestedToken = true; //Evitar too many requests
                return this.loginService.requestToken(true).pipe(
                  mergeMap(token => {
                    requestedToken = false;

                    request = request.clone({
                      headers: request.headers.set(
                        "Authorization",
                        "Bearer " + token
                      )
                    });
                    return next.handle(request);
                  })
                );
              } else {
                if (
                  !request.url.includes("GetRecorte") &&
                  !request.url.includes("PostDoDia")
                ) {
                  this.showAlert();
                }
              }
              return next.handle(request);
            })
          );
        })
      );
    }
    return next.handle(request);
  }
}
