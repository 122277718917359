import { Injectable } from '@angular/core';
import { LoadingController, Platform, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CommonsService {
  constructor(
    private loadingCtrl: LoadingController,
    private toastController: ToastController
  ) {}
  private loadingElement = null;

  public async showLoading(message: string) {
    if (this.loadingElement == null) {
      this.loadingElement = await this.loadingCtrl.create({
        message: message,
        spinner: 'crescent'
      });
      await this.loadingElement.present();
    }
  }

  public async closeLoading() {
    if (this.loadingElement != null) {
      await this.loadingElement.dismiss();
      this.loadingElement = null;
    }
  }
  public async showToast(message, duration) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration
    });

    toast.present();
  }
  public formatBytes(bytes, decimals, sizeReturn): Object {
    if (bytes == 0) {
      return '0 Byte';
    }
    var k = 1024; //Or 1 kilo = 1000
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    var i = Math.floor(Math.log(bytes) / Math.log(k));

    let obj = {
      size: sizes[i],
      value: parseFloat(
        (bytes / Math.pow(k, sizeReturn != null ? 2 : i)).toFixed(decimals)
      )
    };
    return obj;
  }
}
