import { StorageService } from "./../services/storage/storage.service";
import { NavController } from "@ionic/angular";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.storageService.get("usuario").then(data => {
      if (data) {
        if (
          next.routeConfig.path == "**" ||
          (next.routeConfig.path == "configuracoes" && !window['cordova'])
        ) {
          this.navCtrl.navigateRoot("/tabs/recorte");
          return false;
        }
        return true;
      } else {
        if (next.routeConfig.path != "**") {
          this.navCtrl.navigateRoot("/login");
          return false;
        } else {
          return true;
        }
      }
    });
  }

  constructor(
    private storageService: StorageService,
    private navCtrl: NavController
  ) {}
}
