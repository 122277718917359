import { StorageService } from "./../storage/storage.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable, EventEmitter, Output } from "@angular/core";
import { Observable } from "rxjs";
import * as moment from "moment";

@Injectable({
  providedIn: "root"
})
export class RecorteService {
  constructor(
    private storageService: StorageService,
    private http: HttpClient
  ) {}
  private network: string = "Online";

  private emitterRecorteLido: EventEmitter<any> = new EventEmitter();
  private emitterBuscaAvancada: EventEmitter<any> = new EventEmitter();
  private emitterNetwork: EventEmitter<any> = new EventEmitter();

  public emitLido(recorte) {
    this.emitterRecorteLido.emit(recorte);
  }

  public emitBuscaAvancada(filtro) {
    this.emitterBuscaAvancada.emit(filtro);
  }
  public emitNetwork(network) {
    this.emitterNetwork.emit(network);
  }
  public getBuscaAvancada(): Observable<any> {
    return this.emitterBuscaAvancada;
  }
  public getEmitNetwork(): Observable<any> {
    return this.emitterNetwork;
  }
  public getRecorteLido(): Observable<any> {
    return this.emitterRecorteLido;
  }
  public setNetwork(network) {
    this.network = network;
    this.emitNetwork(this.network);
  }

  public getNetwork() {
    return this.network;
  }
  public getRecortes(params: Object): Observable<any> {
    if (this.network == "Online") {
      return this.http.post(
        environment.GRIFON_ALERTA.url + "/Recorte/PostPorEmpresa",
        params
      );
    } else {
      return this.getRecortesStorage(params);
    }
  }
  public getRecortesStorage(params): Observable<any> {
    let pFatorMultiplicacao = params["nFatorMultiplicacao"];

    let nRangeInicio = 1,
      nRangeFim = 1,
      nMultiplicador = 10;

    nRangeFim = pFatorMultiplicacao * nMultiplicador;

    if (pFatorMultiplicacao > 1) nRangeInicio = nRangeFim - nMultiplicador + 1;

    return Observable.create(observer => {
      this.findInArray(nRangeInicio, nRangeFim, params).then(result => {
        observer.next(result);
      });
    });
  }
  public getRecorteDetalhado(idRecorte): Observable<any> {
    if (this.getNetwork() == "Online") {
      return this.http.get(
        environment.GRIFON_ALERTA.url + "/Recorte/GetRecorte/" + idRecorte
      );
    }
    return Observable.create(observer => {
      observer.next(null);
    });
  }

  detalheRecorteBackground(novosRecortes, recortesStorage): Promise<any> {
    // Buscar detalhes em background
    return new Promise(resolve => {
      if (this.getNetwork() == "Online") {
        for (let i = 0; i < novosRecortes.length; i++) {
          for (let j = 0; j < recortesStorage.length; j++) {
            if (novosRecortes[i].nCdRecorte == recortesStorage[j].nCdRecorte) {
              recortesStorage[j] = novosRecortes[i]; //trocar recorte antigo, pelo novo(OBS: Necessário, pois, pode haver atualizações de informações)

              this.http
                .get(
                  environment.GRIFON_ALERTA.url +
                    "/Recorte/GetRecorte/" +
                    novosRecortes[i].nCdRecorte
                )
                .subscribe(res => {
                  //
                  recortesStorage[j].detalhe = res;
                  this.storageService.set("recortes", recortesStorage);
                  resolve(recortesStorage);
                });

              break;
            }
          }
        }
      }
    });
  }
  public findInArray(indexInicio, indexFim, filtros) {
    delete filtros["nCdEmpresa"];
    delete filtros["nFatorMultiplicacao"];
    delete filtros["sCdUsuario"];
    if (filtros["sNrProcesso"]) {
      filtros["sDsNrProcesso"] = filtros["sNrProcesso"];
      delete filtros["sNrProcesso"];
    }
    if (filtros["tDtInicio"] && filtros["tDtFim"]) {
      filtros["tDtInicio"] = filtros["tDtInicio"].substring(
        0,
        filtros["tDtInicio"].length - 9
      );
      filtros["tDtFim"] = filtros["tDtFim"].substring(
        0,
        filtros["tDtFim"].length - 9
      );
    }

    let recortesFiltrados = [];
    return new Promise(resolve => {
      this.storageService.get("recortes").then(recortes => {
        if (recortes) {
          if (Object.keys(filtros).length == 0) {
            //Só fazer paginação caso não houver filtros
            recortes = recortes.slice(indexInicio - 1, indexFim - 1);
          }

          let recortesFiltrados = recortes.filter(recorte => {
            return Object.keys(filtros).every(filtro => {
              if (
                filtro != "sCdUsuario" &&
                filtro != "nCdEmpresa" &&
                filtro != "nFatorMultiplicacao"
              ) {
                if (
                  moment(recorte.tDtPublicacao).isBetween(
                    moment(filtros["tDtInicio"]),
                    moment(filtros["tDtFim"]),
                    null,
                    "[]"
                  ) &&
                  recorte.nCdCaderno == filtros["nCdCaderno"]
                ) {
                  if (filtros["sDsNrProcesso"]) {
                    //Número de processo não é obrigatório, mas caso possua, entrar em validação.
                    if (recorte.sDsNrProcesso == filtros["sDsNrProcesso"])
                      return true;
                    else return false;
                  }
                  return true;
                } else return recorte[filtro] == filtros[filtro];
              }
            });
          });
          resolve(recortesFiltrados);
        }

        resolve(recortesFiltrados);
      });
    });
  }

  public setLido(recorte, usuario): Observable<any> {
    let data = { sCdUsuario: usuario.usuario, nCdRecorte: recorte.nCdRecorte };

    return this.http.post(
      environment.GRIFON_ALERTA.url + "/Recorte/PostUsuarioLeitura",
      data
    );
  }

  public saveToStorage(novosRecortes): Promise<any> {
    return new Promise(resolve => {
      this.storageService.get("recortes").then(recortesStorage => {
        if (recortesStorage) {
          for (let i = 0; i < novosRecortes.length; i++) {
            for (let j = 0; j < recortesStorage.length; j++) {
              let possuiRecorte = false;
              if (
                novosRecortes[i].nCdRecorte == recortesStorage[j].nCdRecorte
              ) {
                possuiRecorte = true;
                let detalhes;
                if (recortesStorage[j].detalhe) {
                  detalhes = recortesStorage[j].detalhe;
                  recortesStorage[j] = novosRecortes[i];
                  novosRecortes[i].detalhe = detalhes;
                } else {
                  recortesStorage[j] = novosRecortes[i];
                }
                break;
              }
              //Quando for o ultimo, verifica se possui no array e se nao possuir executa o push
              if (j == recortesStorage.length - 1 && possuiRecorte == false) {
                recortesStorage.push(novosRecortes[i]);
              }
            }
          }

          this.storageService.set("recortes", recortesStorage).then(result => {
            resolve(result);
          });
        } else {
          //Não possui storage, setar novos Recortes sem precisar fazer validações
          this.storageService.set("recortes", novosRecortes).then(result => {
            resolve(result);
          });
        }
      });
    });
  }
}
