import { StorageService } from "./../storage/storage.service";
import { Platform } from "@ionic/angular";
import { CommonsService } from "./../commons/commons.service";
import { Injectable } from "@angular/core";

import * as sizeof from "object-sizeof";

declare let chrome: any;
declare let window: any;

@Injectable({
  providedIn: "root"
})
export class ConfigService {
  constructor(
    private commonsService: CommonsService,
    private storageService: StorageService,
    private platform: Platform
  ) {}

  public memoryRange = {
    //Memory Range Default
    minMemoryRange: 100,
    maxMemoryRange: 1000,
    stepMemoryRange: 100,
    currentMemoryRange: 1000
  };

  public timeRange = {
    //Time Range Default
    minTimeRange: 30,
    maxTimeRange: 360,
    stepTimeRange: 30,
    currentTimeRange: 360
  };
  public timeRangeBoletim = {
    //Time Range Default
    minTimeRange: 10,
    maxTimeRange: 100,
    stepTimeRange: 10,
    currentTimeRange: 30
  };

  public setDefault(): Promise<any> {
    return new Promise(resolve => {
      let commonsService = this.commonsService;
      let memoryRange = this.memoryRange;
      let timeRange = this.timeRange;
      let timeRangeBoletim = this.timeRangeBoletim;
      let storage = this.storageService;
      if (window['cordova']) {
        //APK(Android) ou (iOS)
        this.platform.ready().then(() => {
          chrome.system.memory.getInfo(function(memory_info) {
            //Obter memória do dispositivo
            let obj: any = commonsService.formatBytes(
              memory_info.capacity,
              2,
              2
            );
            let maxMemoryRange = Math.ceil(obj.value / 1000) * 1000;

            if (maxMemoryRange > memoryRange.maxMemoryRange) {
              memoryRange.maxMemoryRange = maxMemoryRange;
              memoryRange.currentMemoryRange = maxMemoryRange;
              memoryRange.stepMemoryRange =
                memoryRange.stepMemoryRange *
                (memoryRange.maxMemoryRange / 1000);
              memoryRange.minMemoryRange = memoryRange.stepMemoryRange;
              storage
                .set("configuracoes", {
                  memoryRange: memoryRange,
                  timeRange: timeRange,
                  timeRangeBoletim: timeRangeBoletim
                })
                .then(() => {
                  //esperar setar storage para retornar
                  resolve();
                });
            }
          });
        });
      } else {
        //Testes ionic Serve (Remover depois)
        storage
          .set("configuracoes", {
            memoryRange: memoryRange,
            timeRange: timeRange,
            timeRangeBoletim: timeRangeBoletim
          })
          .then(() => {
            //esperar setar storage para retornar
            resolve();
          });
      }
    });
  }

  public async checkRange(recortes) {
    if (!window['cordova']) return;
    let config: any = await this.storageService.get("configuracoes");

    if (!config) {
      await this.setDefault();
      config = await this.storageService.get("configuracoes");
    }
    let boletins = await JSON.parse(localStorage.getItem("boletins"));
    if (!boletins) boletins = [];

    await this.checkMemoryRange(recortes, config);
    await this.checkTimeRange(recortes, config);
  }
  public async checkTimeRangeBoletim(boletins) {
    const dataLimite = new Date();

    const config: any = await this.storageService.get("configuracoes");

    dataLimite.setDate(
      dataLimite.getDate() - config.timeRangeBoletim.currentTimeRange
    );

    const boletinsFiltrados = boletins.filter(boletim => {
      return new Date(boletim.tDtEnvio).getTime() > dataLimite.getTime();
    });

    const boletinsRemovidos = boletins.filter(boletim => {
      return new Date(boletim.tDtEnvio).getTime() <= dataLimite.getTime();
    });

    this.removerPDFBoletins(boletinsRemovidos);
    this.storageService.set("boletins", boletinsFiltrados);
  }

  public removerPDFBoletins(boletins: Array<any>) {
    boletins.forEach(boletim => {
      if (boletim.file) {
        const [path, file] = boletim.file.path.split(/(?=Boletim)/g);

        window.resolveLocalFileSystemURL(path, function(dir: any) {
          dir.getFile(file, { create: false }, fileEntry => {
            fileEntry.remove(),
              error => {
                console.log("erro");
                console.log(error);
              },
              () => {
                console.log("arquivo inexistente");
              };
          });
        });
      }
    });
  }
  public async checkMemoryRange(recortes, config) {
    do {
      var resultSize: any = await this.commonsService.formatBytes(
        sizeof.default(recortes),
        2,
        null
      );

      if (resultSize.value > config.memoryRange.maxMemoryRange) {
        let dateOlder = Math.min.apply(
          null,
          recortes.map(recorte => new Date(recorte.tDtPublicacao).getTime())
        );
        let indexOfObject = recortes
          .map(recorte => {
            return new Date(recorte.tDtPublicacao).getTime();
          })
          .indexOf(dateOlder);
        await recortes.splice(indexOfObject, 1);
      } else {
        this.storageService.set("recortes", recortes);
      }
    } while (resultSize.value > config.memoryRange.maxMemoryRange);
  }

  public async checkTimeRange(recortes, config) {
    let dataLimite = new Date();
    dataLimite.setDate(
      dataLimite.getDate() - config.timeRange.currentTimeRange
    );

    let recortesFiltrados = recortes.filter(recorte => {
      return new Date(recorte.tDtPublicacao).getTime() > dataLimite.getTime();
    });
    this.storageService.set("recortes", recortesFiltrados);
  }
}
