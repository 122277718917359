import { StorageService } from "./../storage/storage.service";
import { SecurityService } from "./../security/security.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { Observable, from } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class LoginService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private securityService: SecurityService
  ) {}

  private emitterNovoUsuario: EventEmitter<any> = new EventEmitter();

  public emitNovoUsuario(usuario) {
    this.emitterNovoUsuario.emit(usuario);
  }
  public getNovoUsuario(): Observable<any> {
    return this.emitterNovoUsuario;
  }
  login(usuario: Object): Observable<any> {
    return this.http.post(
      environment.GRIFON_ALERTA.url + "/Usuario/Login",
      usuario
    );
  }

  async getUsuario() {
    return this.storageService.get("usuario");
  }

  setUsuario(usuario) {
    this.storageService.set("usuario", usuario);
  }

  requestToken(decrypt): Observable<any> {
    const body =
      "grant_type=" +
      "client_credentials" +
      "&scope=" +
      //'ServicoAppApiResource ServicoApiResource' + Necessario quando tiver Notification
      "ServicoAppApiResource" +
      "&client_id=" +
      "ServicoApp" +
      "&client_secret=" +
      environment.IDENTITY_SERVER.secret;

    let headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    });
    let options = {
      headers: headers
    };

    let promise = new Promise(res => {
      this.http
        .post(environment.IDENTITY_SERVER.url + "/connect/token", body, options)
        .subscribe(token => {
          this.securityService
            .encrypt(token["access_token"], environment.passwordToken)
            .then(tokenEncrypted => {
              if (decrypt) {
                //Necessário decriptografar na chamada do interceptor
                this.storageService.set("token", tokenEncrypted).then(() => {
                  this.getToken().subscribe(tokenDecrypted => {
                    res(tokenDecrypted);
                  });
                });
              } else {
                res(tokenEncrypted);
              }
            });
        });
    });
    return from(promise);
  }

  getToken() {
    let promise = new Promise(res => {
      this.storageService.get("token").then(token => {
        if (token) {
          this.securityService
            .decrypt(token, environment.passwordToken)
            .then(decrypted => {
              res(decrypted);
            });
        }
      });
    });
    return from(promise);
  }
}
