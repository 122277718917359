export const environment = {
  production: false,
  passwordToken: "CaPiVaRa",
  GRIFON_ALERTA: {
    url: "https://gateway.grifonalerta.com.br/api/v1",
  },
  IDENTITY_SERVER: {
    url: "https://gateway.grifonalerta.com.br",
    secret: "C-{Y1>ycM;lRxpi*",
  },
  BOLETIM_DIARIO: {
    url: "https://grifonalerta.com.br/Download/DownloadEnvioRecorte",
  },
};
