import { CommonsService } from "../../../services/commons/commons.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ModalController, AlertController } from "@ionic/angular";

import * as moment from "moment";
import * as textMask from "vanilla-text-mask/dist/vanillaTextMask.js";
import { IonicSelectableComponent } from "ionic-selectable";
import { ProcessoService } from "../../../services/processo/processo.service";

@Component({
  selector: "app-search-filter-process",
  templateUrl: "./search-filter-process.page.html",
  styleUrls: ["./search-filter-process.page.scss"],
})
export class SearchFilterProcessPage implements OnInit {
  mask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
  maskedInputController;

  @ViewChild("input", { read: ViewContainerRef }) public input;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.maskedInputController = textMask.maskInput({
        inputElement: this.input.element.nativeElement,
        mask: this.mask,
      });
    });
  }

  ngOnDestroy() {
    this.maskedInputController.destroy();
    localStorage.removeItem("modalOpen");
  }
  public pesquisaAvancadaForm: FormGroup;

  selectedCaderno: any;
  filtros: any = "none";
  emptyFilters: boolean = false;
  processo;
  cadernos = [];
  @ViewChild("cadernosComponent2") cadernoComponent: IonicSelectableComponent;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private alertCtrl: AlertController,
    private commonsService: CommonsService,
    private processoService: ProcessoService
  ) {}

  selectClicado: boolean = true;

  public onOpen() {
    this.commonsService.closeLoading();
  }
  public carregarModalCadernos() {
    this.commonsService.showLoading("Carregando cadernos");
  }
  public clear() {
    this.cadernoComponent.clear();
    this.cadernoComponent.close();
  }

  public confirm() {
    this.cadernoComponent.confirm();
    this.cadernoComponent.close();
  }

  public clicouSelect() {
    this.selectClicado = true;
  }
  toggleItems() {
    console.log(this.selectedCaderno);
    this.cadernoComponent.toggleItems(
      this.cadernoComponent.itemsToConfirm.length ? false : true
    );
  }
  maxDate;
  ngOnInit() {
    localStorage.setItem("modalOpen", "1");
    this.maxDate = new Date(2018, 5, 5);
    this.pesquisaAvancadaForm = this.formBuilder.group({
      caderno: [null, Validators.compose([Validators.required])],
      dataInicio: [],
      dataFim: [],
      processo: [],
    });

    this.loadCourts();
    let filtros: any;
    if (localStorage.getItem("filtrosProcesso")) {
      filtros = JSON.parse(localStorage.getItem("filtrosProcesso"));
    }
    if (filtros) {
      this.selectedCaderno = filtros.nCdCaderno;
      if(filtros.tDtInicio) {
        this.pesquisaAvancadaForm
        .get("dataInicio")
        .setValue(moment(filtros.tDtInicio));
      }
      if(filtros.tDtFim) {      
        this.pesquisaAvancadaForm.get("dataFim").setValue(moment(filtros.tDtFim));
      }
      if (filtros.processo) {
        this.processo = filtros.processo;
      } 
    }
  }
  public async loadCourts() {
    this.cadernos = await this.processoService.findActiveCourts();
    this.selectedCaderno = [];
    this.selectedCaderno.push(this.cadernos[0]);
  }

  async pesquisar() {
    let dataFimMoment;
    if (this.pesquisaAvancadaForm.get("dataFim").value) {
      dataFimMoment = this.pesquisaAvancadaForm.get("dataFim").value.format();
      if (
        this.pesquisaAvancadaForm
          .get("dataFim")
          .value.diff(
            this.pesquisaAvancadaForm.get("dataInicio").value,
            "days",
            false
          ) > 30
      ) {
        const alert = await this.alertCtrl.create({
          header: "Atenção",
          message: "Intervalo maior que 30 dias.",
          buttons: ["OK"],
        });
        return await alert.present();
      }
    }
    if (this.pesquisaAvancadaForm.valid) {
      console.log(this.pesquisaAvancadaForm.get("caderno").value);
      let listaCodigoCadernos = this.pesquisaAvancadaForm.get("caderno").value[0] //Mudar quando API receber múltiplos cadernos
        .nCdCaderno;
        console.log(listaCodigoCadernos);
      let filtro = {
        arrCdCaderno: listaCodigoCadernos,
      };
      if (
        this.pesquisaAvancadaForm.get("dataInicio").value &&
        this.pesquisaAvancadaForm.get("dataFim").value
      ) {
        let dataInicioMoment = this.pesquisaAvancadaForm
          .get("dataInicio")
          .value.format();
        (filtro["tDtInicio"] = dataInicioMoment.substring(
          0,
          dataInicioMoment.length - 6
        )),
          (filtro["tDtFim"] = dataFimMoment.substring(
            0,
            dataFimMoment.length - 6
          ));
      }

      if (
        this.pesquisaAvancadaForm.get("processo").value &&
        this.pesquisaAvancadaForm.get("processo").value.length < 5
      ) {
        const alert = await this.alertCtrl.create({
          header: "Atenção",
          message: "Número de processo deve ter ao menos 5 caracteres.",
          buttons: ["OK"],
        });
        return await alert.present();
      }
      /* let listaCodigoCadernos = this.pesquisaAvancadaForm
        .get("caderno")
        .value.map(a => a.nCdCaderno);
 */

      if (this.pesquisaAvancadaForm.get("processo").value) {
        filtro["processo"] = this.pesquisaAvancadaForm
          .get("processo")
          .value.toString();
      }
      this.filtros = filtro;
      if(this.pesquisaAvancadaForm.get("dataInicio").value && !this.pesquisaAvancadaForm.get("dataFim").value) { //se preencher só a primeira data, preencher a segunda com o mesmo valor
        let dataInicioMoment = this.pesquisaAvancadaForm
          .get("dataInicio")
          .value.format();
        (filtro["tDtInicio"] = dataInicioMoment.substring(
          0,
          dataInicioMoment.length - 6
        )),
          (filtro["tDtFim"] = dataInicioMoment.substring(
            0,
            dataInicioMoment.length - 6
          ));
      }
      let filtrosStorage = {
        nCdCaderno: this.pesquisaAvancadaForm.get("caderno").value,        
      };
      if(this.pesquisaAvancadaForm.get("dataInicio").value) {
        filtrosStorage['tDtInicio'] = this.pesquisaAvancadaForm.get("dataInicio").value.valueOf();
      }
      if(this.pesquisaAvancadaForm.get("dataFim").value) {
        filtrosStorage['tDtFim'] = this.pesquisaAvancadaForm.get("dataFim").value.valueOf();

      }
      if(this.pesquisaAvancadaForm.get("processo").value) {
        filtrosStorage['processo'] = this.pesquisaAvancadaForm.get("processo").value;
      }
      localStorage.setItem("filtrosProcesso", JSON.stringify(filtrosStorage));
      this.processoService.emitBuscaAvancada(this.filtros);
      this.closeModal();
    } else {
      if (
        (document.getElementById("inputDataInicio")["value"] &&
          !this.pesquisaAvancadaForm.get("dataInicio").value) ||
        (document.getElementById("inputDataFim")["value"] &&
          !this.pesquisaAvancadaForm.get("dataFim").value)
      ) {
        this.pesquisaAvancadaForm
          .get("dataInicio")
          .setValue(
            moment(
              document.getElementById("inputDataInicio")["value"],
              "DD/MM/YYYY"
            )
          );
        this.pesquisaAvancadaForm
          .get("dataFim")
          .setValue(
            moment(
              document.getElementById("inputDataFim")["value"],
              "DD/MM/YYYY"
            )
          );
        this.pesquisar();
      } else {
        const alert = await this.alertCtrl.create({
          header: "Atenção",
          message: "Caderno é de preenchimento obrigatório.",
          buttons: ["OK"],
        });
        return await alert.present();
      }
    }
  }

  async closeModal() {
    await this.modalCtrl.dismiss();
    if (this.emptyFilters === true) {
      this.processoService.emitBuscaAvancada(null);
    }
  }

  public limparFormulario() {
    this.emptyFilters = true;
    this.pesquisaAvancadaForm.reset();
    localStorage.removeItem("filtrosProcesso");
    this.filtros = null;
  }
}
