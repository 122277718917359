import { Injectable } from '@angular/core';
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  constructor() {}

  async encrypt(value, password): Promise<any> {
    const result = await crypto.AES.encrypt(value, password);
    return result.toString();
  }

  async decrypt(value, password): Promise<any> {
    const bytesValue = await crypto.AES.decrypt(value, password);
    const decryptedPassword = await bytesValue.toString(crypto.enc.Utf8);
    return decryptedPassword;
  }
}
