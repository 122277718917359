import { Storage } from "@ionic/storage";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class StorageService {
  constructor(private storage: Storage) {}

  public async set(name, value): Promise<any> {
    if (this.isChrome()) {
      typeof value === "string"
        ? localStorage.setItem(name, value)
        : localStorage.setItem(name, JSON.stringify(value));

      return new Promise(res => {
        res(name);
      });
    } else {
      return this.storage.set(name, value);
    }
  }

  public get(name): Promise<any> {
    if (this.isChrome()) {
      let result;
      try {
        result = JSON.parse(localStorage.getItem(name));
      } catch (rtt) {
        result = localStorage.getItem(name);
      }

      return new Promise(res => {
        res(result);
      });
    } else {
      return this.storage.get(name);
    }
  }

  public remove(name) {
    if (this.isChrome()) {
      localStorage.removeItem(name);
    } else {
      this.storage.remove(name);
    }
  }

  public clear() {
    if (this.isChrome()) {
      localStorage.clear();
    } else {
      this.storage.clear();
    }
  }

  private isChrome(): boolean {
    const is_chrome = /chrome/.test(navigator.userAgent.toLowerCase());
    return is_chrome;
  }
}
