import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LoginService } from '../login/login.service';

interface Params {
  pageNumber: number;
  pageSize: number;
  courtId?: number;
  lawsuitNumber?: number;
  updateDateStart?: string;
  updateDateEnd?: string; 
}

interface ResponseProcess {
  total: number;
  lawsuits: Array<Object>;
}

interface ResponseCourts {
  nCdCaderno: number;
  sDsCaderno: string;
  tMonitorarInicio: string;
  tMonitorarFim: string;
}

@Injectable({
  providedIn: 'root'
})

export class ProcessoService {

  private emitterBuscaAvancada: EventEmitter<any> = new EventEmitter();

  constructor(private loginService: LoginService, private httpClient: HttpClient) { }

  public emitBuscaAvancada(filtros) {
    this.emitterBuscaAvancada.emit(filtros);
  }

  public getBuscaAvancada(): Observable<any> {
    return this.emitterBuscaAvancada; 
  }

  public async findProcess(params: Params): Promise<ResponseProcess> {
    const responseUser = await this.loginService.getUsuario();
    
    const data = {
      nCdEmpresa: responseUser.empresa,
      sCdUsuario: responseUser.usuario
    }

    const { pageNumber, pageSize } = params;

    let filter = new HttpParams();
    filter = filter.append('pageNumber', pageNumber.toString());
    filter = filter.append('pageSize', pageSize.toString());

    if(params.courtId) {
      filter = filter.append('courtId', params.courtId.toString());      
    }
    if(params.lawsuitNumber) {
      filter = filter.append('lawsuitNumber', params.lawsuitNumber.toString());      
    }
    if(params.updateDateStart && params.updateDateEnd) {
      filter = filter.append('updateDateStart', params.updateDateStart.toString());      
      filter = filter.append('updateDateEnd', params.updateDateEnd.toString());      
    }

    return this.httpClient.post<ResponseProcess>(`${environment.IDENTITY_SERVER.url}/api/v1/Processo/Index`, data, {
      params: filter
    }).toPromise();
  }

  public async findDetailsProcess(nCdMonitoramento: number) {
    const responseUser = await this.loginService.getUsuario();
    
    const data = {
      nCdEmpresa: responseUser.empresa,
      sCdUsuario: responseUser.usuario
    }
    return this.httpClient.post(`${environment.IDENTITY_SERVER.url}/api/v1/Processo/ShowDetails/${nCdMonitoramento}`, data).toPromise();
  }

  public async findActiveCourts() {
    return this.httpClient.get<ResponseCourts[]>(`${environment.IDENTITY_SERVER.url}/api/v1/Processo/GetActiveCourts`).toPromise();
  }

  public async updateIsNew(nCdMonitoramento: number) {
    const responseUser = await this.loginService.getUsuario();
    
    const data = {
      nCdEmpresa: responseUser.empresa,
      sCdUsuario: responseUser.usuario
    }

    return this.httpClient.put(`${environment.IDENTITY_SERVER.url}/api/v1/Processo/UpdateIsNew/${nCdMonitoramento}`, data).toPromise();
  }
}
